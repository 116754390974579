import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
} from "gatsby-plugin-react-i18next";
import Layout from "../pages/components/layout";
import AudioPlayer from "../pages/components/audioplayer";
import "../css/index.css";
import Shield from "../images/sketches/viking-shield-white.svg";
import Ship from "../images/sketches/viking-ship-white.svg";
import Coins from "../images/sketches/coins-white.svg";
import Odin from "../images/sketches/odin.svg";
import ArrowsRight from "../images/sketches/shot-arrows-right.svg";
import Insular from "../images/sketches/insular-art.svg";
import Next from "../images/logos/next-button.svg";
import Previous from "../images/logos/previous-button.svg";
import Slide from "react-reveal/Slide";
import TestAudio from "../audio/WATERFORD Stop1.mp3";


function List(props) {
  return (
    <Slide left>
      <li className="list-arrow py-4 pl-2 text-white">
        <a href={props.link}><Trans>{props.name}</Trans></a>
      </li>
    </Slide>
  );
}

export default function Reginald() {
  const [isActive, setIsActive] = useState(false);

  const { languages, changeLanguage } = useI18next();
  const { t } = useTranslation();

  const cards = [
    {
      id: 1,
      cardName: "Reginald'\s Tower",
      text: "https://www.waterfordtreasures.com/reginalds-tower",
    },
    {
      id: 2,
      cardName: "King of the Vikings Virtual Reality Adventure",
      text: "http://www.kingofthevikings.com/",
    },
  ];

  return (
    <Layout pageTitle="Reginald's Tower">
    <div className="lg:hidden">

      <Helmet>
      <meta charset="utf-8" />
      <meta http-equiv="X-UA-Compatible" content="IE=edge" />
      <title>Waterford Digital Story Trail - Reginald's Tower</title>
      <meta name="description" content="Reginald's Tower. A location on the digital story trail celebrating the history of Waterford." />
      <meta property="og:title" content="Waterford Digital Story Trail - Reginald's Tower" />
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://digitalstorytrail.visitwaterford.com/reginaldstower" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
      </Helmet>

      <div className="w-full bg-waterford-ocean h-24"></div>
      <section className="reginald-bg w-full h-full">
        <div className="w-9/12">
          <h1 className="font-lower p-6 text-white">
            <span className="font-heading text-xl">
              <Trans>Reginald's Tower</Trans>
            </span>
            <br />
            <span className="font-bold text-4xl"><Trans>ReginaldSubtitle</Trans></span>
          </h1>
        </div>

        <div className="pt-16 text-center">
          <div className=" text-center">
            <a
              href="https://arweb.app/?zid=z/yUHm1c&rs=&menu=&toolbar="
            >
              <div className="ar-orange-bubble rounded-full h-32 w-32 bg-waterford-orange hover:bg-opacity-100 mx-auto ml-8 md:ml-auto">
                <div className="animate-pulse transition duration-500 ease-in-out transform hover:scale-105 motion-reduce:transform-none p-16 rounded-full mx-auto ring ring-waterford-orange"></div>
              </div>
            </a>
            <div>
              <AudioPlayer url={TestAudio} transcript={'/transcripts/reginaldtranscript'} />
            </div>
          </div>
        </div>
      </section>
      <div className="accordion pt-16 bg-waterford-ocean "></div>

      <button className="w-full p-6 bg-waterford-ocean ring ring-waterford-cyan-30 text-waterford-orange text-left">
        <div
          className="accordion-title grid grid-cols-6 py-6"
          onClick={() => setIsActive(!isActive)}
        >
          <div className="col-span-5 font-lower text-2xl">
            <Trans>LearnMore</Trans>
          </div>
        </div>
        {isActive && (
          <>
            <ul className="accordion-content list-outside font-heading text-xl pl-12">
              {cards.map((card) => (
                <List key={card.id} name={card.cardName} link={card.text} />
              ))}
            </ul>
          </>
        )}
      </button>

      <section className="location-hero py-16">
        <div>
          <div>
            <div className="w-7/12">
              <h1 className="font-lower text-white p-6">
                <br />
                <span className="font-bold text-4xl"><Trans>DidYouKnow</Trans></span>
              </h1>
            </div>
            <div className="text-waterford-red w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <h3 className="text-8xl ml-6 lg:mx-auto">1</h3>
                <p className="text-xl col-span-2 font-bold mt-2">
                  <Trans>ReginaldFactOne</Trans>
                </p>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                  <Trans>ReginaldFactContentOne</Trans>
                </p>
              </div>
              <div className=" py-6 grid grid-cols-3">
                <Slide up>
                  <div className="w-full"></div>
                  <img
                    className="w-full col-span-2"
                    src={Coins}
                    alt="Viking Shield"
                  />
                </Slide>
              </div>
            </div>

            <div className="text-waterford-orange w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <div></div>
                <p className="text-xl col-span-2 font-bold mt-2">
                  <Trans>ReginaldFactTwo</Trans>
                </p>
                <h3 className="text-8xl lg:mx-auto">2</h3>
              </div>
              <div className="w-6/6 py-6 grid grid-cols-4 lg:mx-auto font-heading">
                <div></div>
                <p className="text-white text-sm col-span-2">
                <Trans>ReginaldFactContentTwo</Trans>
                </p>
              </div>
              <div className=" py-6 grid grid-cols-3">
                <Slide left>
                  <img
                    className="w-full col-span-2"
                    src={Ship}
                    alt="Viking Shield"
                  />
                  <div className="w-full"></div>
                </Slide>
              </div>
            </div>

            <div className="text-waterford-cyan w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <h3 className="text-8xl ml-4 lg:mx-auto">3</h3>
                <p className="text-xl col-span-2 font-bold mt-2">
                  <Trans>ReginaldFactThree</Trans>
                </p>
              </div>
              <div className="w-4/6 py-6 ml-6 lg:mx-auto font-heading">
                <p className="text-white text-sm">
                <Trans>ReginaldFactContentThree</Trans>
                </p>
              </div>
              <div className="mr-0 md:mr-auto py-6 grid grid-cols-3">
                <Slide up>
                  <div className="w-full"></div>
                  <img
                    className="w-full col-span-2"
                    src={Shield}
                    alt="Viking Shield"
                  />
                </Slide>
              </div>
            </div>

            <div className="text-waterford-blue w-6/6 py-6">
              <div className="grid grid-cols-4 gap-0 font-lower">
                <div></div>
                <p className="text-xl ml-6 col-span-2 font-bold mt-2 pr-2">
                  <Trans>ReginaldFactFour</Trans>
                </p>
                <h3 className="text-8xl lg:mx-auto">4</h3>
              </div>
              <div className="w-6/6 py-6 grid grid-cols-4 lg:mx-auto font-heading">
                <div></div>
                <p className="text-white ml-6 text-sm col-span-2">
                  <Trans>ReginaldFactContentFour</Trans>
                </p>
              </div>

              <div className="w-7/12 pt-16">
                <h1 className="font-lower text-white p-6">
                  <span className="font-heading text-xl"><Trans>Reginald's Tower</Trans></span>
                  <br />
                  <span className="font-bold text-4xl"><Trans>Timeline</Trans></span>
                </h1>
              </div>
              <div className="text-waterford-red w-6/6 py-6 px-6">
                <div className="py-6 grid grid-cols-3">
                  <img className="w-full col-span-2" src={Odin} alt="Odin" />
                  <div className="w-full"></div>
                </div>
                <div className="grid grid-cols-2 gap-0 font-lower text-right">
                  <div></div>
                  <h3 className="text-6xl lg:mx-auto">902</h3>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 text-right">
                  <div></div>
                  <p className="text-white text-sm col-span-2">
                    <Trans>ReginaldTimelineOne</Trans>
                  </p>
                </div>
              </div>

              <div className="bg-cyan-circle text-waterford-ocean w-6/6 py-16">
                <div className="py-6 grid grid-cols-3">
                  <Slide left>
                    <img
                      className="w-full col-span-2 pb-12"
                      src={Ship}
                      alt="Viking Ship"
                    />
                    <div className="w-full"></div>
                  </Slide>
                </div>
                <div className="grid grid-cols-2 gap-0 font-lower px-6">
                  <h3 className="text-6xl lg:mx-auto">914</h3>
                  <div></div>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading pb-12 w-7/12">
                  <p className="text-waterford-ocean text-sm">
                    <Trans>ReginaldTimelineTwo</Trans>
                  </p>
                  <div></div>
                </div>
              </div>

              <div className="text-waterford-red w-6/6 py-6">
                <div className="py-6 lg:mx-auto font-heading grid grid-cols-2 gap-sm ">
                  <Slide left>
                  <div></div>
                    <img
                      className="w-full py-2"
                      src={ArrowsRight}
                      alt="Arrows Right"
                    />
                  </Slide>
                </div>

                <div className="py-6 lg:mx-auto font-heading grid grid-cols-2 gap-sm">
                  <Slide left delay={200}>
                  <div></div>
                    <img
                      className="w-full py-2"
                      src={ArrowsRight}
                      alt="Arrows Right"
                    />
                  </Slide>
                </div>

                <div className="grid grid-cols-2 gap-0 font-lower px-6 text-right">
                  <div></div>
                  <h3 className="text-6xl lg:mx-auto">918</h3>
                </div>
                <div className="py-6 ml-6 lg:mx-auto font-heading grid grid-cols-3 px-6 text-right">
                  <div></div>
                  <p className="text-white text-sm col-span-2">
                    <Trans>ReginaldTimelineThree</Trans>
                  </p>
                </div>
              </div>

              <div className="text-waterford-red w-6/6 py-6">
                <div className="py-6 lg:mx-auto grid grid-cols-2 gap-2">
                  <div className="px-6">
                    <h3 className="text-6xl lg:mx-auto font-lower pb-4">921</h3>
                    <p className="text-white text-sm font-heading">
                      <Trans>ReginaldTimelineFour</Trans>
                    </p>
                  </div>
                  <img
                    className="w-full"
                    src={Insular}
                    alt="Maclise Aoife Strongbow Painting"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-2">
          <div>
            <Link to="/beachtower">
              <img className="w-2/6 mx-auto" src={Previous} alt="Previous" />
            </Link>
            <p className="text-center font-heading pt-4 text-white">
              <Trans>PreviousStop</Trans>
            </p>
          </div>
          <div>
            <Link to="/greyfriars">
              <img className="w-2/6 mx-auto" src={Next} alt="Next" />
            </Link>
            <p className="text-center font-heading pt-4 text-white">
              <Trans>NextStop</Trans>
            </p>
          </div>
        </div>
      </section>
    </div>
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
